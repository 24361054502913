<template>
  <div class="table-head">
    <a-form-model class="form-editSegment" :model="form">
      <div class="d-flex flex-column">
        <div class="mb-3 d-flex">
          <a-input-search v-model="loginSearch" placeholder="Input search text" enter-button @change="tableUpdate"
            class="mr-2" style="padding-top: 1px;" />
          <a-button @click="openUserModal" type="primary" ghost><a-icon type="plus" /> New user</a-button>
        </div>
        <a-table :columns="columns" :data-source="accounts" :rowKey="(record, index) => record.id" :loading="loading"
          :pagination="pagination" @change="changePagination" class="segments__table operations-table" :row-selection="rowSelection">
          <template slot="createdAt" slot-scope="text, record">
            <span class="mr-2">{{ $moment(record.created_at).format('DD.MM.YY') }}</span>
            <span>{{ $moment(record.created_at).format('HH:mm') }}</span>
          </template>
          <template slot="actions" slot-scope="text, record">
            <div class="row-operations">
              <span>
                <a-popconfirm placement="left" title="Sure to delete this user?" @confirm="() => deleteUser(record.id)">
                  <a-icon class="font-size-18 text-danger delete-icon" type="delete" />
                </a-popconfirm>
              </span>
            </div>
          </template>
        </a-table>
      </div>
      <a-row>
        <a-col :span="24" class="segment-footer pt-4">
          <div class="d-flex justify-content-between">
            <a-popconfirm v-if="selectedRowKeys.length" title="Sure to delete these users?" @confirm="deleteUsers">
              <a-button class="deleteButton" type="danger" :disabled="loading">
              Delete
            </a-button>
          </a-popconfirm>
            <div class="ml-auto text-right">
              <a-button @click="closeModal" :disabled="loading">
                Cancel
              </a-button>
              <a-button type="primary" style="margin-left: 10px;" @click="closeModal" :disabled="loading" :loading="loading">
                Save
              </a-button>
            </div>
          </div>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>
<script>
import ViewscreenService from '@/services/api/apiViewscreenService'
import debounce from 'lodash.debounce'

export default {
  props: ['segmentId'],
  data: () => ({
    loading: false,
    deleteLoading: false,
    form: {
      users: [],
    },
    loginSearch: '',
    users: [],
    selectedRowKeys: [],
    accounts: null,
    isFormEmpty: true,
    pagination: {
      pageSize: 10,
      current: 1,
      total: 0,
    },
    columns: [
      {
        title: 'ID',
        dataIndex: 'id',
        width: '10%',
      },
      {
        title: 'Created',
        dataIndex: 'created_at',
        width: '20%',
        scopedSlots: { customRender: 'createdAt' },
      },
      {
        title: 'Login',
        dataIndex: 'login',
        width: '20%',
      },
      {
        title: 'Bundle ID',
        dataIndex: 'bundle_id',
        width: '20%',
      },
      {
        title: 'Actions',
        dataIndex: 'operation',
        key: 'operation',
        align: 'right',
        scopedSlots: { customRender: 'actions' },
      },
    ],
  }),
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: (record) => ({
          props: {
            disabled: false,
          },
        }),
      }
    },
  },
  created() {
    this.getSegmentUsers(this.segmentId)
  },
  methods: {
    async getSegmentUsers(segmentId, login) {
      this.loading = true
      try {
        const response = await ViewscreenService.getSegmentAccounts(segmentId, login, this.pagination.current, this.pagination.pageSize)
        if (response.data.data) {
          this.accounts = response.data.data
          this.pagination.total = response.data.meta.total
        }
      } finally {
        this.loading = false
      }
    },
    async deleteUsers() {
      this.loading = true
      try {
        const data = this.selectedRowKeys
        const response = await ViewscreenService.deleteSegmentAccounts(this.segmentId, data)
        if (response.data.success === true) {
          this.selectedRowKeys = []
          this.accounts = this.accounts.filter(account => !data.includes(account.id))
          this.$notification.success({
            message: 'Selected users have been successfully deleted from segment',
          })
        }
      } catch (error) {
        this.$notification.error({
          message: 'Error while deleting users',
          description: error.message,
        })
      } finally {
        this.loading = false
      }
    },
    async deleteUser(accountId) {
      this.loading = true
      try {
        const response = await ViewscreenService.deleteSegmentAccount(this.segmentId, accountId)
        if (response.data.success === true) {
          const deletedIndex = this.accounts.findIndex(account => account.id === accountId)
          if (deletedIndex !== -1) {
            this.accounts.splice(deletedIndex, 1)
          }
          this.$notification.success({
            message: 'User has been successfully deleted from segment',
          })
        }
      } catch (error) {
        this.$notification.error({
          message: 'Error while deleting user',
          description: error.message,
        })
      } finally {
        this.loading = false
      }
    },
    tableUpdate: debounce(function () {
      this.getSegmentUsers(this.segmentId, this.loginSearch)
    }, 500),
    changePagination(pagination) {
      this.pagination = pagination
      this.getSegmentUsers(this.segmentId, this.search)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    openUserModal() {
      this.$emit('userModal')
    },
    closeModal() {
      this.$emit('closeEditSegmentModal')
    },
  },
}
</script>

<style lang="scss" scoped>
.delete-icon {

  &:hover,
  :focus {
    cursor: pointer;
    color: #a71d2a !important;
  }
}

.segment-footer {
  border-top: 1px solid #d9d9d9;
  padding-top: 24px !important;
}

.table-head .ant-table-thead>tr>th {
  background-color: #fafafa !important;
  font-weight: 600 !important;
}

.disabled-row {
  background-color: #fafafa;
  color: #595959;
  font-weight: 400;
}

.form-editSegment {
  .ant-select-selection {
    background-color: transparent !important;
    border: 0 !important;
  }
}

.checkbox-list {
  display: flex !important;
  flex-wrap: wrap;

  &__item {
    flex: 1 1 30%;
    max-width: 31%;
    padding: 5px 0 !important;
  }
}

.ant-btn-background-ghost.ant-btn-primary:hover,
.ant-btn-background-ghost.ant-btn-primary:focus {
  color: #ffffff !important;
}
</style>
