<template>
  <div class="table-head">
    <a-form-model class="form-viewscreenModal" ref="viewscreensForm" :model="form" >
      <a-row>
        <a-input-search v-model="viewscreenSearch" placeholder="Input search text" enter-button @search="getListViewsreenTabs" :loading="loading" class="mb-3" style="padding-top: 1px;" />
            <a-radio-group v-if="viewscreens.length" name="radioGroup" v-model="form.viewscreenId">
              <a-radio v-for="viewscreen in viewscreens" :key="viewscreen.id" :value="viewscreen.id" class="viewscreen-radio">
                {{ viewscreen.name }}
              </a-radio>
            </a-radio-group>
            <div v-else class="mb-3">
              <span>No viewscreens with such name are found</span>
            </div>
      </a-row>
      <a-row class="footer-modal">
        <a-col class="text-right" :span="24">
          <a-button @click="closeModal"> Cancel </a-button>
          <a-button :loading="loading" type="primary" @click="saveViewscreen" class="ml-2">
            Save
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>
  </div>
</template>
<script>
import ViewscreenService from '@/services/api/apiViewscreenService'

export default {
  props: ['viewscreenId'],
  data: () => ({
    form: {
      viewscreenId: '',
    },
    loading: false,
    viewscreens: [],
    viewscreenSearch: '',
  }),
  computed: {
    selectedViewscreen () {
      if (this.form.viewscreenId) {
        return this.viewscreens.find(viewscreen => viewscreen.id === this.form.viewscreenId).name
      }
      return null
    },
  },
  methods: {
    async getListViewsreenTabs(name) {
      this.loading = true
      try {
        const response = await ViewscreenService.getViewscreenTabs(name)
        if (response.data.data) {
          this.viewscreens = response.data.data
        }
      } finally {
        this.loading = false
      }
    },
    saveViewscreen () {
      this.$emit('viewscreenChosen', this.form.viewscreenId, this.selectedViewscreen)
    },
    closeModal() {
      this.$emit('cancelModal')
    },
  },
  async created() {
    await this.getListViewsreenTabs()
  },
}
</script>

<style lang="scss">
.viewscreen-radio {

  display: block !important;
  height: 30px;
  line-height: 30px;

}
</style>
