<template>
  <a-form-model ref="segmentsForm" :model="form" :rules="rules" class="add-segments">
    <a-form-model-item ref="name" label="Name" prop="name">
      <a-input v-model="form.name" placeholder="Enter segment name" />
    </a-form-model-item>
    <a-form-model-item>
      <a-upload-dragger class="segments-uploader" :before-upload="beforeUpload" :multiple="false"
        :remove="handleRemove" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" :disabled="loading">
        <div>
          <div>
            <a-icon :type="loaderIcon" class="text-primary" style="font-size: 48px" />
            <div>
              Click or drag .xlsx file to this area to upload<br />
              <span class="text">Maximum file size: 10MB</span><br />
            </div>
          </div>
        </div>
      </a-upload-dragger>
    </a-form-model-item>
    <hr>
    <a-row>
      <a-col class="text-right" :span="24">
        <a-button @click="closeAddSegmentsModal">
          Cancel
        </a-button>
        <a-button type="primary" style="margin-left: 10px;" @click="onSubmit" :loading="loading">
          Add
        </a-button>
      </a-col>
    </a-row>
  </a-form-model>
</template>
<script>

import ViewscreenService from '@/services/api/apiViewscreenService'

export default {
  name: 'segmentsModal',
  components: {
  },
  data() {
    return {
      form: {
        name: '',
      },
      loading: false,
      importFile: null,
      rules: {
        name: [{ required: true, message: 'Please input segment name', trigger: 'blur' }],
      },
    }
  },
  computed: {
    loaderIcon() {
      return this.loading ? 'loading' : this.importFile ? 'check' : 'inbox'
    },
  },
  mounted() {
  },
  methods: {
    beforeUpload(file) {
      this.loading = true
      this.importFile = file
      this.loading = false
      return false
    },
    handleRemove() {
      this.importFile = null
    },
    closeAddSegmentsModal() {
      this.$emit('closeModalFunction')
    },
    submitSegmentModal(newSegment) {
      this.$emit('addNewSegment', newSegment)
    },
    async onSubmit() {
      const isValid = await new Promise((resolve) => {
        this.$refs.segmentsForm.validate(resolve)
      })
      if (!isValid) {
        return false
      }
      return await this.createSegment()
    },
    async createSegment() {
      this.loading = true

      const formData = new FormData()
      formData.append('name', this.form.name)
      if (this.importFile) {
        const file = this.importFile
        if (file.size / 1024 / 1024 > 10) {
          this.$notification.error({
            message: 'Error while importing segment',
            description: 'File is too heavy (max 10MB)',
          })
          this.loading = false
          return
        }
        formData.append('accounts', file)
      }
      try {
        const segmentsResponse = await ViewscreenService.createSegment(formData)

        if (segmentsResponse.data) {
          this.$notification.success({
            message: 'Segment has been successfully imported',
          })
          this.submitSegmentModal(segmentsResponse.data.data)
        }
      } catch (err) {
        this.$notification.error({
          message: 'Something went wrong',
          description: err.response.data.error.message,
        })
        console.log(err)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
<style scoped lang="scss">
.add-segments {
  .segments-uploader {
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: auto;
    position: relative;

    .ant-upload-list-item-name {
      color: #1890ff;
    }

  }

  .ant-form-item-label {
    padding: 0px !important;
  }
}
</style>
